<template>
  <div style="width: 100%;">
    <v-row dense no-gutters>
      <v-col cols="12">
        <Table
          :key="tableKey"
          :items="telemetries"
          :loading="loading"
          :loading-total-sum="loadingTotalSum"
          :total-sum="totalSum"
          @fetchTelemetries="fetchTelemetries($event)"
        ></Table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from './TelemetriesDatatable'
import {
  getTelemetryDailyUsingGET as getDailyTelemetries,
  getTelemetryMonthlyUsingGET as getMonthlyTelemetries,
  getTelemetryYearlyUsingGET as getYearlyTelemetries
} from '@/utils/api'
import { mapActions } from 'vuex'

export default {
  components: {
    Table
  },
  data() {
    return {
      err: '',
      success: '',
      tableKey: 0,
      loading: false,
      telemetries: { items: [], meta: {} },
      totalSum: 0,
      loadingTotalSum: true
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.addSnackbar({
        message: this.$route.params.message,
        timeout: 5000,
        color: 'success'
      })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    fetchAllTelemetries(type, date) {
      this.loadingTotalSum = true

      if (type === 'day') {
        getDailyTelemetries({ day: date })
          .then((res) => {
            this.totalSum = res.data.data.items.reduce((acc, item) => acc + item.count, 0)
            this.loadingTotalSum = false
          })
          .catch((error) => {
            this.loadingTotalSum = false
            console.log(error)
          })
      } else if (type === 'month') {
        getMonthlyTelemetries({ month: date })
          .then((res) => {
            this.totalSum = res.data.data.items.reduce((acc, item) => acc + item.count, 0)
            this.loadingTotalSum = false
          })
          .catch((error) => {
            this.loading = false
            console.log(error)
          })
      } else if (type === 'year') {
        getYearlyTelemetries({ year: date })
          .then((res) => {
            this.totalSum = res.data.data.items.reduce((acc, item) => acc + item.count, 0)
            this.loadingTotalSum = false
          })
          .catch((error) => {
            this.loadingTotalSum = false
            console.log(error)
          })
      }
    },
    fetchTelemetries(options) {
      this.loading = true
      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      if (options && options.date && options.key) {
        obj[options.key] = options.date
      }

      if (options.key === 'day') {
        getDailyTelemetries(obj)
          .then((res) => {
            this.telemetries = res.data.data
            this.loading = false
            this.fetchAllTelemetries('day', options.date)
          })
          .catch((error) => {
            this.loading = false
            console.log(error)
          })
      } else if (options.key === 'month') {
        getMonthlyTelemetries(obj)
          .then((res) => {
            this.telemetries = res.data.data
            this.loading = false
            this.fetchAllTelemetries('month', options.date)
          })
          .catch((error) => {
            this.loading = false
            console.log(error)
          })
      } else if (options.key === 'year') {
        getYearlyTelemetries(obj)
          .then((res) => {
            this.telemetries = res.data.data
            this.loading = false
            this.fetchAllTelemetries('year', options.date)
          })
          .catch((error) => {
            this.loading = false
            console.log(error)
          })
      }
    }
  }
}
</script>
